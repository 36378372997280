@import '../../assets/sass/global';

.renewalChange {

    &__info {
        display: block;
        color: $color-orient;
        font-weight: bold;
        font-size: 20px;
        line-height: 22px;
        margin-bottom: 30px;
    }

    &__notes-textarea {
        display: block;
        width: 100%;
        max-width: 650px;
        border: 1px solid $color-dusty-gray;
        box-sizing: border-box;
        border-radius: 4px;
        padding: 15px;
        min-height: 250px;
        outline: none;
        margin: 17px 0 15px;
        font-size: 15.8px;
        line-height: 17px;

        &::placeholder {
            color: $color-silver;
          }
    }

    &__notes-error {
        font-size: 20px;
        line-height: 22px;
        color: $warning;
        font-style: normal;
        font-weight: normal;
    }

    &__endTenancyCopy {
        max-width: 650px;
        margin-bottom: 50px;
    }

    .card {
        max-width: 550px;
        margin-left: 0;
        margin-top: 45px;
    }

    .button {
        margin-top: 25px;
    }
}
