@import '../../../assets/sass/global.scss';

.modal {
  background-color: rgba($color-tradewind, .2);
  height: 100%;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 100;

  &__container{
    background: $color-white;
    border-radius: 8px;
    box-shadow: $box-shadow;
    min-height: 200px;
    left: 50%;
    max-height: 90%;
    max-width: 90%;
    position: absolute;
    padding: 20px;
    transform: translateX(-50%);
    top: 50%;
    transform: translate(-50%, -50%);
    width: 800px;
    z-index: 1;
    overflow: scroll;

    h3 {
      color: #4a4a4a;
    }
  }
  
  &__close {
    position: absolute;
    bottom: 20px;
    width: calc(100% - 40px);

    button { 
      float: right
    }
  }
}