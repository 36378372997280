.unitImages {
  &__default {
    max-width: 100%;
  }
  .button--plain {
    text-align: right;
    width: 100%;
    max-width: 100%;
    margin-right: 10px;
    padding: 2px 10px 0;
  }
};