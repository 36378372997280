@import '../../../assets/sass/variables.scss';
@import "../../../assets/sass/mixins.scss";

.datepicker {
  padding: 20px 0 50px;
  position: relative;
  max-width: 500px;

  @include md {
    max-width: 700px;
  }
}

.date-container {
  margin-left: -30px;
  overflow-y: scroll;
  margin-bottom: 15px;
  margin-top: 10px;

  @include md {
    margin-left: 0;
    overflow: visible;
    text-align: center;
  }
}

.react-datepicker {
  font-family: $font-family-base;
  border: none;
  background-color: transparent;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: stretch;

  @include md {
    padding-left: 0;
    overflow: visible;
  }

  &__month-container {
    background-color: $color-white;
    border: 1px solid $color-dusty-gray;
    padding: 20px 15px 15px;
    float: none;
    display: inline-block;
    vertical-align: top;
    height: 300px;

    @include md {
      &:last-child {
        margin-left: 20px;
      }
    }
  }

  &__current-month {
    font-size: 14px !important;
    font-weight: 200;
    margin-bottom: 10px;
    color: $color-smalt-blue;
    padding: 0 8px;
  }

  &__day,
  &__day-name {
    margin: 0 !important;
    padding: 5px;
    width: 35px;
    height: 35px;
    color: $color-orient;
    font-size: 14px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
  }

  &__day-name {
    font-weight: 700;
  }
  
  &__day {
    &--today {
      color: $color-smalt-blue;
      font-weight: 700;
    }
    &:focus {
      outline: none
    }
    &--selected {
      color: $color-white;
      background-color: $color-tradewind;
      border-radius: 50%;
    }
    @include hover-focus {
      color: $color-white;
      background-color: $color-tradewind;
      border-radius: 50%;
      cursor: pointer;
    }

    &--disabled {
      color: $color-jungle-mist;

      @include hover-focus {
        color: $color-tradewind;
        background-color: transparent;
      }
    }
  }

  &__month {
    margin: 0;
  }

  &__navigation {
    border: none;
    position: absolute;
    top: 40%;
    font-family: $font-family-icons !important;
    line-height: 1;
    padding: 0;

    @include hover-focus {
      cursor: pointer;
    }
  &:focus {
      outline: none
    }
    &--next {
      right: -6px;

      @include md {
        right: 0;
      }

      &::before {
        content: '\ea42';
        color: $color-smalt-blue;
        right:0;
        font-size: 30px;
      }
    }

    &--previous {
      left: -6px;

      @include md {
        left: 0;
      }

      &::before {
        content: '\ea44';
        color: $color-smalt-blue;
        right: 0;
        font-size: 30px;
      }
    }
  }
}
