@import './assets/sass/global.scss';

.custom-auth {
  &--other  {
    background-image: url('./assets/images/authenticator-background.jpg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    min-height: 100%;
    width: 100%;
    position: absolute;
    left: 0;
    top: 0;
    z-index: -1;
  };

  &__center {
    background: $color-white;
    width: 100%;
    max-width: 460px;
    margin: 0 auto;
    margin-top: 68px;

    @include auth-md {
      margin-top: 0;
      height: 100%;
      width: 500px;
    }
  }

  &__margin {
    margin-top: 68px;

    @include auth-md {
      margin-top: 0;
    }
  }

  &__title {
    font-family: $font-family-headings;
    color: $color-scorpion;
    padding: 15px 0 45px;
    font-size: 33px;
    text-transform: lowercase;
    text-align: center;
    
    &-text {
      color: $color-tradewind;
    }

    @include sm {
      font-size: 41.5px;
    }

    @include auth-md {
      padding: 90px 0 120px;
      font-size: 50px;
    }
  }
};

