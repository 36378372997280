@import './variables.scss';
@import './fonts.scss';
@import './normalize.scss';
@import './icons.scss';
@import './mixins.scss';

* {
  box-sizing: border-box;
}
@import "../../../node_modules/bootstrap-4-grid/scss/grid.scss";

body {
  color: $color-tundora;
  font-size: $font-size-base;
  font-family: $font-family-base;

  &::after {
    content: '';
    pointer-events: none;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: transparent;
    transition: background-color .2s ease;

    @include lg {
      display: none;
    }
  }

  @include md {
    font-size: $font-size-base-md;
  }
}

* {
  box-sizing: border-box;
}

h1, .h1,
h2, .h2,
h3, .h3,
h4, .h4,
h5, .h5,
h6, .h6 {
  font-family: $font-family-headings;
}

.gutter-narrow {
  margin-left: -5px;
  margin-right: -5px;

  > .col,
  > [class*="col-"] {
    padding-left: 5px;
    padding-right: 5px;
    margin: 0;
  }
}

.grey-box {
  background-color: #e6e6e6;
  border: var(--demo-component-border);
  padding: 20px;
}

.gr-body-fixed {
  margin: 0;
  height: 100%;
  overflow: hidden;
  position: relative;
}

html.gr-body-fixed {  /* This is to force the body to have the same rules if we add this class to html tag */
  body {
    margin: 0;
    height: 100%;
    overflow: hidden;
    position: relative;
  }
}

.container-md {
  @include md {
    max-width: 720px;
    margin: 0 auto;
    width: 100%;
  }

  @include lg {
    max-width: 960px;
  }

  @include xl {
    max-width: 1140px;
  }
}

a:focus {
  outline: 2px solid $color-smalt-blue;
}

a:active {
  outline: none;
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  white-space: nowrap;	
  -webkit-clip-path: inset(50%);	
  clip-path: inset(50%);
  overflow: hidden;
  clip: rect(0,0,0,0);
  border: 0;
}

.page-wrapper {
  @include md {
    width: 80%;
    margin: 0 10%;
    position: relative;
  }
}

.centered--fixed {
  position: fixed;
  z-index: 1000;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
