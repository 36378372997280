@import '../../../assets/sass/global';

.emailInput {
    margin: 0 0 20px 0;
    display: flex;
    flex-direction: column;

    &__input {
        margin-bottom: 5px;
        padding: 10px 16px;
        width: 100%;
        &--error {
            outline: none;
            border: 1px solid $warning;
        }
    }

    &__label {
        font-weight: bold;
        color: $color-orient;
        margin-bottom: 10px;
    }

    &__error-message {
        color: $warning;
        width: 100%;
    }
}