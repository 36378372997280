@import '../../../assets/sass/global';

.checkboxInput  {
    display: flex;
    align-items: center;
    cursor: pointer;
    position: relative;
    min-height: 30px;
    margin: 0 auto 30px;

    &__checkbox {
        position: absolute;
        opacity: 0;
        cursor: pointer;


        &:checked + .checkboxInput__checkbox--custom:after {
            content: "";
            position: absolute;
            display: block;
            left: 7px;
            bottom: 4px;
            width: 13px;
            height: 23px;
            border: solid $color-orient;
            border-width: 0 1px 1px 0;
            -webkit-transform: rotate(33deg);
            -ms-transform: rotate(33deg);
            transform: rotate(33deg);
        }
    }

    &__checkbox--custom {
        -webkit-appearance: none;
        position: relative;
        border: 2px solid $color-orient;
        border-radius: 8px;
        min-width: 30px;
        height: 30px;
        top: 0;
        left: 0;
        cursor: pointer;
    }

    &__label {
        margin-left: 7px;
        font-size: 16px;
        line-height: 18px;
    }
}