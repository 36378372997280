@import '../../../assets/sass/global';

.tenancyTerm {
    flex-direction: column;

    &__info-block {
        margin-bottom: 30px;
    }
    &__info-text {
        font-style: italic;
        color: $color-black;
    }
    &__break-clause-block {
        margin-bottom: 50px;
    }

    &__break-clause {
        color: $color-black;
    }
}