@import '../../../assets/sass/global';

.radioInput  {
    margin: 0 auto;
    box-shadow: $box-shadow;
    display: flex;
    align-items: center;
    cursor: pointer;
    position: relative;
    min-height: 28px;
    width: 100%;
    max-width: 400px;
    margin: 0 auto 50px;
    border-radius: 8px;

    &__label {
      padding: 24px;
      width: 100%;
      cursor: pointer;
      border-radius: 8px;
    }

    &__radio {
        position: absolute;
        opacity: 0;

      &:checked + .radioInput__label {
        // background-color: $color-burnt-sienna;
        border: none;
      }
      &:checked + .radioInput__label .radioInput__radio--custom {
          background-color: $color-burnt-sienna;
          border: none;
      }
    }

    &__radio--custom {
        -webkit-appearance: none;
        position: absolute;
        background-color: $color-anti-flash-white;
        border: 1px solid $color-dusty-gray;
        width: 28px;
        height: 28px;
        top: 50%;
        margin-top: -14px;
        left: 20px;
        cursor: pointer;
        border-radius: 50%;
        display: block;
    }

    &__text {
      margin-left: 2.5em;
      width: calc(100% - 52px);
      display: block;
      text-align: left;
      font-weight: bold;
      font-size: 16px;
      color: $color-orient;

      &--centered {
        margin-left: 24px;
        text-align: center;
      }
    }

    &.alternateRadio {
      background-color: $color-cararra;
      margin: 0 0 10px;

      &:last-child {
        margin-bottom: 50px;
      }

      .radioInput__text {
        color: $color-black;
      }

      .radioInput__radio:checked + .radioInput__label {
        background-color: $color-dusty-gray;
          
        .radioInput__text {
          color: $color-white;
        }
        & .radioInput__radio--custom {
          background-color: $color-orient;
          border: 2px solid white;
        }
      }

    }
}
