@import '../../../assets/sass/global';

.button {
  padding: 10px 15px;
  color: $color-white;
  background-color: $color-oxford-blue;
  border: 2px solid $color-oxford-blue;
  border-radius: 50px;
  text-decoration: none;
  text-align: center;
  max-width: 210px;
  min-width: 170px;
  cursor: pointer;
  text-transform: uppercase;
  font-family: $font-family-headings;
  transition: background-color .2s ease;
  @include font-size('link-button');

  @include md {
    @include font-size('link-button', 'lg');
  }

  @include hover-focus {
    background-color: $color-white;
    color: $color-oxford-blue;
  }

  &--secondary {
    background-color: $color-tradewind;
    border-color: $color-tradewind;

    @include hover-focus {
      color: $color-boulder;
    }
  }

  &--tertiary {
    background-color: $color-smalt-blue;
    border-color: $color-smalt-blue;

    @include hover-focus {
      background-color: $color-william;
      border-color: $color-william;
      color: $color-white;
    }
  }

  &--plain {
    padding: 0;
    background-color: transparent;
    color: $color-orient;
    border-radius: 0;
    text-decoration: underline;
    border: none;
    min-width: auto;
    display: block;
    text-transform: none;
    font-family: $font-family-headings;

    @include hover-focus {
      color: $color-orient;
      text-decoration: none;
      outline: none;
    }
  }

  &[disabled] {
    background-color: $color-silver;
    color: $color-boulder;
    border-color: $color-silver;
    cursor: not-allowed;
    text-decoration: none;
  }
}
