@import '../../../assets/sass/global';

.contactRoleForm {
    &__buttonContainer {
        display: flex;
        justify-content: flex-end;
    }
    &__button {
        &:not(:last-child) {
            margin-right: 20px;
        }
    }
}
