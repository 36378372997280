@import '../../../assets/sass/mixins.scss';

.title-header {
  margin-bottom: 15px;
  &__category {
    text-transform: uppercase;
    margin: 0 0 5px;
    font-family: $font-family-headings;
    
    @include lg {
      @include font-size('header-category');
    }
    
    .large & {
      @include font-size('header-title');
    }
  }
  
  &__title {
    color: $color-orient;
    font-size: 20px;
    font-family: $font-family-headings;
    margin: 0 0 20px;
    margin-bottom: 0;
    
    @include lg {
      @include font-size('header-title-large');
      margin-bottom: 0;
    }
    
    .large & {
      @include font-size('header-title');
    }
  }
}
