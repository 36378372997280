@import '../../../assets/sass/global';

.quote {
  max-width: 400px;
  color: black;
  margin: 0 auto 50px;

  &--selected {
    box-shadow: 0 0 0pt 6pt $color-burnt-sienna;
    border-radius: 8px;
  }

  &__limit{

    display:flex;

    h5 {
      color:#00627b;
      font-size:1.2em;

    }
      
  }
  
  &__image {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: $color-silver;
    text-align: center;
    color: white;
  }

  &__unit-image {
    width: 100%;
    height: 200px;
    position: absolute;
    object-fit: cover;
  }

  h2 {
    color: $color-orient;
  }

  h4 a {
    text-decoration: none;
  }

  &__terms {
    border-top: 1px solid $color-silver;
    border-bottom: 1px solid $color-silver;
    margin-top: 30px;
  }

  &__wishlist {
    margin-bottom: 20px;
  }

  &__button {
    text-align: center;
    margin-top: 25px;

    .button {
      outline: none;
    }
  }

  .button--plain {
    display: inline-block;
  }

  .premium {
    background-color: $color-burnt-sienna;
    color: $color-white;
    font-weight: 700;
    text-transform: uppercase;
    font-size: 14px;
    padding: 5px;
    position: absolute;
    right: -32px;
    top: 6px;
    transform: rotate(45deg);
    width: 130px;
    text-align: center;
  }

  &__banner {
    background-color: $color-white;
    width: 100%;
    position: absolute;
    left: 0;
    top: 60px;
    text-align: center;
    padding: 15px;
    font-weight: 700;
    color: $color-tradewind;
    z-index: 2;
  }

  &__term {
    display: block;
    margin-top: 5px;
  }

  .card {
    position: relative;
    overflow: hidden;
    margin-bottom: 0;
    height: 100%;

    padding: 0;
  }

  &__content {
    padding: 0 30px 25px;
  }

  &[disabled] {
    background-color: $color-silver;
    color: rgba($color-boulder, .6);
    border-color: $color-silver;
    border-radius: 8px;
    cursor: not-allowed;
    text-decoration: none;

    .carousel {
      opacity: .4;
    }

    h2 {
      color: rgba($color-boulder, .6);
    }
    .card:hover {
      background-color: $color-silver;
      cursor: not-allowed;
    }
  }
}