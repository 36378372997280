/* Fonts */
$font-family-base: 'fs_elliotregular', -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", sans-serif;
$font-family-headings: 'fs_elliotbold';
$font-family-icons: 'mygrainger-icons';

/* Colours */
$color-tradewind: #62B1AD;
$color-smalt-blue: #4E8383;
$color-william: #385F5F;
$color-sea-nymph: #78A6A3;
$color-cutty-sark: #4E807F;
$color-mantle: #8D9F90;
$color-dixie: #E49F1C;
$color-burnt-sienna: #EA6641;
$color-rolling-stone: #798084;
$color-boulder: #7C7C7C;
$color-shuttle-gray: #505D68;
$color-wild-sand: #F6F6F6;
$color-gallery: #EAEAEA;
$color-white: #FFFFFF;
$color-concrete: #F2F2F2;
$color-orient: #00627B;
$color-black: #333333;
$color-alto: #DCDCDC;
$color-oxford-blue: #303E4A;
$color-cararra: #F0EFEC;
$color-silver: #C8C8C8;
$color-jungle-mist: #C2D1D9;
$color-dusty-gray: #979797;
$color-alabaster: #f8f8f8;
$color-friar-gray: #7C7C7B;
$color-scorpion: #606060;
$color-tundora: #4A4A4A;
$color-mercury: #E5E5E5;
$color-elephant: #143F5C;

// TODO - THESE COLOURS ARE DUPLICATED - WHICH SET DO WE NEED??

/* Containers */
$content-max-width: 1140px;

/* Breakpoints */
$breakpoint-sm: 576px;
$breakpoint-md: 768px;
$breakpoint-lg: 992px;
$breakpoint-xl: 1200px;

$breakpoint-auth-md: 600px;

$color-white: #FFFFFF;
$color-off-white: #F0EFEC;
$color-anti-flash-white: #F3F3F3;
$color-black: #000000;
$color-heavy-metal: #212721;
$color-dixie: #E5A01D;
$color-smalt-blue: #4E8382;
$color-jaffa: #F36F4A;
$color-tradewind: #62B1AD;
$color-friar-gray: #7C7C7B;
$color-shuttle-gray: #63666A;
$color-oxford-blue: #303E4A;
$color-orient: #00627B;

$color-mindshaft: #333;
$color-concrete: #F2F2F2;
$color-alto: #D8D8D8;
$color-tundora: #4A4A4A;
$color-dusty-gray: #979797;
$color-dusty-gray-light: #9B9B9B;
$color-wild-sand: #F6F6F6;
$color-slate-blue: #143f5c;

$color-slate-blue-gradient: rgba(20, 63, 92, 0.7);
$color-tradewind-gradient: rgba(98, 177, 173, 0.7);

$warning: #FF0000;

$font-size-base: 1rem;
$font-size-base-md: 1rem;
$font-size-small: 0.75rem;

$font-sizes: (
  header-title: 22px,
  header-title-large: 26px,
  header-category: 18px,
  nav-link: 13px,
  p: 16px,
  link-button: 14px,
  tab-header: 14px,
  table-header: 14px,
);

$box-shadow: (
  1px 1px 4px 0 rgba(51, 51, 51, .23),
);
