@import '../../../assets/sass/global';

.errorBanner {
    background-color: $color-burnt-sienna;
    border-radius: 8px;
    box-shadow: $box-shadow;
    color: $color-white;
    font-size: 18px;
    padding: 15px;
    margin-bottom: 30px;

    &__title {
      margin-top: 0;
    }

    &__text {
      margin: 5px 0;
    }

    a{
      color: $color-white;
    }
}