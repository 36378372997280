@import 'variables.scss';

@mixin xs() {
  @media all and (max-width: $breakpoint-sm) {
    @content;
  }
}

@mixin sm() {
  @media all and (min-width: $breakpoint-sm) {
    @content;
  }
}

@mixin md() {
  @media all and (min-width: $breakpoint-md) {
    @content;
  }
}

@mixin lg() {
  @media all and (min-width: $breakpoint-lg) {
    @content;
  }
}

@mixin xl() {
  @media all and (min-width: $breakpoint-xl) {
    @content;
  }
}

@mixin column($width) {
  flex-basis: percentage($width / 12);
  max-width: percentage($width / 12);
  padding: 0 15px;
}

@mixin hover-focus() {
  &:hover,
  &:focus {
    @content;
  }
}

@mixin auth-md() {
  @media all and (min-width: $breakpoint-auth-md) {
    @content;
  }
}

@mixin hover-focus() {
  &:hover,
  &:focus {
    @content;
  }
}

@mixin cover {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

@mixin tertiary($bg, $fg, $bg-hover, $fg-hover) {
  background-color: $bg;
  color: $fg;

  @include hover-focus {
    background-color: $bg-hover;
    color: $fg-hover;
    transition: all ease-out 0.2s;
  }
}

@mixin clearfix() {
  &::after {
    display: block;
    content: "";
    clear: both;
  }
}

@mixin IE10-IE11() {
  @media screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none) {
    /* IE10+ specific styles go here */
    @content;
  }
}

@mixin font-size($size, $bp: '', $important: null) {
  font-size: get-size-by-bp($size, $bp, $important);
}

@function get-size-by-bp($size, $bp, $important: null) {
  $actualSize: map-get($font-sizes, $size);
  $rawsize: remove-unit($actualSize);

  @if $bp == 'xl' { $rawsize: $rawsize + 4; }
  @if $bp == 'lg' { $rawsize: $rawsize + 2; }
  @if $bp == 'sm' { $rawsize: $rawsize - 2; }

  @if $important { $important: ' !important'; }

  @return #{$rawsize}px#{$important};
}

@function remove-unit($number) {
  @if type-of($number) == 'number' and not unitless($number) {
    @return $number / ($number * 0 + 1);
  }
  @return number;
}