@import '../../assets/sass/global';

.referencingProgress {
  &__container {
    display: grid;
    grid-template-columns: auto auto;
    @include md {
      grid-template-columns: auto auto;
    }
  }

  .card {
    background-color: $color-alto;
    padding: 10px;

    @include md {
      padding: 30px;

    }
  }
}