@import '../../../assets/sass/global';

@keyframes blink {
  0% {
    opacity: .2;
  }
  20% {
    opacity: 1;
  }
  100% {
    opacity: .2;
  }
}

.loading {
  width: 200px;
  height: 200px;
  border-radius: 8px;
  box-shadow: $box-shadow;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  justify-content: center;

  span {
    animation-name: blink;
    animation-duration: 1.4s;
    animation-iteration-count: infinite;
    animation-fill-mode: both;

    &:nth-child(2) {
      animation-delay: .2s;
    }

    &:nth-child(3) {
      animation-delay: .4s;
    }
  
  }

}