@import "../../../assets/sass/mixins.scss";


.social-links {
  margin: 0 0 40px;

  @include md {
    margin-bottom: 60px;
  }

  li {
    margin-right: 10px;

    &:last-child {
      margin-right: 0;
    }
  }
}

.social-link {
  width: 55px;
  height: 55px;
  border-radius: 50%;
  background-color: $color-tradewind;
  color: $color-tradewind;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  text-decoration: none;
  transition: background-color .2s linear, color .2s linear;

  @include hover-focus {
    text-decoration: none;
    color: $color-tradewind;
    background-color: $color-white;
  }
}


