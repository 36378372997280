@import '../../assets/sass/global';

.build-offer {
    margin: 2em 0;


    &__parking {
        margin: 1em 0;
        &__label {
            display: flex;
            align-items: center;
        }
        &__checkmark {
            -webkit-appearance: none;
            background-color: transparent;
            border: 2px solid $color-boulder;
            width: 20px;
            height: 20px;
            display: inline-block;
            vertical-align: middle;
            cursor: pointer;
            margin-left: 0.5em;
            top: 3px;
        }

        &__checkbox {
            padding: 0;
            height: initial;
            width: initial;
            margin-bottom: 0;
            display: none;
            cursor: pointer;


            &:checked + .build-offer__parking__checkmark {
              border: 2px solid $color-tradewind;
            }

            &:checked + .build-offer__parking__checkmark:after {
              content: '';
              display: block;
              margin-left: 6px;
              width: 6px;
              height: 14px;
              border: 2px solid $color-tradewind;
              border-width: 0 2px 2px 0;
              transform: rotate(45deg);
            }
          }
    }
}
