@import '../../assets/sass/global';

.create-account {
  background-image: url('../../assets/images/authenticator-background.jpg');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 100%;
  width: 100%;
  position: absolute;
  left: 0;
  top: 0;
  z-index: -1;

  &__message {
    display: flex;
    justify-content: space-between;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 99;
    box-shadow: 1px 1px 5px 0 rgba(0,0,0,0.3);
    padding: 1em;
    background-color: $color-burnt-sienna;
    color: #fff;
  }

  &__center {
    background: $color-white;
    width: 100%;
    max-width: 460px;
    margin: 0 auto;
    margin-top: 68px;

    @include auth-md {
      margin-top: 0;
      height: 100%;
      width: 500px;
    }
  }

  &__title {
    font-family: $font-family-headings;
    color: $color-scorpion;
    padding: 3em 0 2em;
    font-size: 33px;
    text-transform: lowercase;
    text-align: center;

    &-text {
      color: $color-tradewind;
    }

    @include sm {
      font-size: 41.5px;
    }

    @include auth-md {
      font-size: 50px;
    }
  }

  &__form {
    position: relative;
    width: 100%;
    min-width: 300px;
    max-width: 460px;
    margin: 0px;
    padding: 35px 25px;
    text-align: left;
    display: inline-block;
    border-radius: 6px;
    box-sizing: border-box;
    box-shadow: none;

    @include auth-md {
      padding: 35px 40px;
    }

    &__header {
      margin-bottom: 24px;
      font-size: 18px;
      font-weight: 500;
      color: #152939;
    }
    &__field {
      margin-bottom: 22px;
      display: flex;
      flex-direction: column;
      color: #152939;

      &__label {
        font-size: 14px;
        margin-bottom: 8px;
      }
      &__input {
        display: block;
        width: 100%;
        padding: 16px;
        line-height: 1.15;
        font-size: 16px;
        background-color: $color-white;
        border: 1px solid #C4C4C4;
        border-radius: 3px;
        box-sizing: border-box;
      }
    }

    &__checkbox {
      -webkit-user-select: none;
      user-select: none;
      margin-bottom: 1em;

      label {
        position: relative;
        display: flex;

        &:before {
          content:'';
          flex-shrink: 0;
          -webkit-appearance: none;
          background-color: transparent;
          border: 2px solid $color-boulder;
          width: 24px;
          height: 24px;      
          display: inline-block;
          position: relative;
          vertical-align: middle;
          cursor: pointer;
          margin-right: 0.5em;
          top: 3px;
        }

        a {
          color: $color-tradewind;
          text-decoration: none;
        }
      }
      
      input {
        padding: 0;
        height: initial;
        width: initial;
        margin-bottom: 0;
        display: none;
        cursor: pointer;


        &:checked + label:before {
          border: 2px solid $color-tradewind;
        }

        &:checked + label:after {
          content: '';
          display: block;
          position: absolute;
          top: 6px;
          left: 9px;
          width: 6px;
          height: 14px;
          border: 2px solid $color-tradewind;
          border-width: 0 2px 2px 0;
          transform: rotate(45deg);
        }
      }
    }

    &__footer {
      margin-top: 30px;
      @include auth-md {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
      }
      &__submit {
        text-transform: uppercase;
        font-size: 12px;
        line-height: 1.5;
        cursor: pointer;
        user-select: none;
        padding: 14px 0;
        letter-spacing: 1.1px;
        border: 1px solid transparent;
        @include auth-md {
          width: auto;
          min-width: 153px;
        }

        &:hover {
          border: 1px solid $color-tradewind;
        }
      }
    }
  }

  &__login {
    text-align: center;
    padding-top: 1em;
    padding-bottom: 2em;

    > a {
      text-decoration: none;
      color: $color-tradewind;

      &:hover {
        text-decoration-line: underline;
      }
    }
  }
};

