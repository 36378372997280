@import "../../../assets/sass/mixins.scss";

.container {
  max-width: $content-max-width;
  margin: 0 auto;
  padding: 0 15px;
}

.gr-footer {
  background-color: $color-shuttle-gray;
  color: $color-white;
  padding: 40px 0 50px;
  margin-top: 50px;

  &--feed {
    @include md {
      margin-top: 190px;
    }

    @include lg {
      margin-top: 240px;
    }
  }

  @include lg {
    padding-bottom: 70px;
  }

  &__instagram {
    display: none;

    @include md {
      display: block;
      margin-top: -170px;
      font-weight: 700;
      color: $color-smalt-blue;
    }

    @include lg {
      margin-top: -215px;
    }
  }

  &__instagram-text {
    margin-bottom: 10px;
    padding: 0 10px;
  }

  &__instagram-link {
    color: $color-smalt-blue;
    text-transform: none;
    display: inline-block;
    margin-left: 5px;

    @include hover-focus {
      color: $color-tradewind;
    }
  }

  &__feed {
    margin-bottom: 35px;

    li {
      padding: 0;
      margin: 0;
      overflow: hidden;
    }
  }

  &__feed-link {
    display: block;
    transform: scale(1);
    transition: transform .3s linear;
    vertical-align: middle;

    img {
      display: block;
    }

    @include hover-focus {
      transform: scale(1.1);
      transition: transform .3s linear;
    }
  }

  &__row {
    @include md {
      align-items: flex-end;
    }
  }

  &__pages {
    li {
      margin: 0 0 2px;
      display: inline-block;

      &::after {
        content: '|';
        margin: 0 5px;
      }

      &:last-child::after {
        content: none;
      }
    }
  }

  &__social-links {
    margin: 0 0 40px;

    @include md {
      margin-bottom: 60px;
    }

    li {
      margin-right: 10px;

      &:last-child {
        margin-right: 0;
      }
    }
  }

  &__social-link {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-color: #46473E;
    color: $color-white;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    font-size: 20px;
    text-decoration: none;
    transition: background-color .2s linear, color .2s linear;

    @include hover-focus {
      text-decoration: none;
      color: $color-shuttle-gray;
      background-color: $color-white;
    }
  }

  &__logo {
    max-width: 120px;
    margin-bottom: 15px;

    @include md {
      max-width: 160px;
    }
  }

  &__grainger-info {
    margin-bottom: 40px;

    @include md {
      max-width: 75%;
      font-size: 18px;
      margin-bottom: 0;
    }

    @include lg {
      max-width: 70%;
    }
  }

  &__page-link {
    color: $color-white;
    font-size: 14px;
    text-decoration: none;
    padding: 0 10px;
    border-left: 1px solid $color-white;;

    &:first-child {
      border-left: none;
      padding-left: 0;
    }
    
    @include hover-focus {
      color: $color-white;
      text-decoration: underline;
    }
  }

  &__phone-number {
    font-size: 14px;
    margin-bottom: 20px;
    display: block;
    color: $color-white;
    text-decoration: none;

    @include hover-focus {
      color: $color-white;
      text-decoration: underline;
    }
  }

  &__text {
    font-size: 14px;
    margin-bottom: -15px;

    a {
      color: $color-white;

      @include hover-focus {
        color: $color-white;
      }
    }
  }
}
