@import "../../../assets/sass/mixins.scss";

.basket {
  border-radius: 7px;
  background-color: $color-tradewind;
  padding: 10px 20px 20px 20px ;
  color: $color-white;
  font-weight: 700;

  &__wishlist {
    text-align: left;
    margin-top: 10px;
    color: white;
    
  }

  .modal {
    color: $color-tundora;
  }

  span {
    font-weight: 400;
  }

  @include md {
    min-height: 400px;
  }

  h2 {
    text-transform: uppercase;
  }

  &__flex-container {
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
  }

  .mb-20 {
    margin-bottom: 20px;
  }
}
