@import '../../../assets/sass/global';

.statusTile {
  width: 150px;
  height: 170px;
  padding: 10px 3px;
  border-radius: 8px;
  text-align: center;
  box-shadow: $box-shadow;
  background-color: $color-white;
  margin: 10px auto 0;

  @include sm {
    width: 170px;
    margin: 10px auto;
  }

  &__status {
    font-size: 14px;
  }


  @include lg {
    margin: 20px auto;
    padding: 20px;
    width: 230px;
    height: 230px;
  }

  &__heading {
    margin-top: 0;
    font-size: 14px;
    color: $color-orient;
    text-transform: uppercase;
    margin-bottom: 5px;

    @include lg {
      font-size: 16px;
      margin-bottom: 5px;
    }
  }

  &__name {
    height: 40px;
    font-weight: bold;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;

    @include lg {
      height: 50px;
    }
  }

  &__icon {
    font-size: 20px;
    width: 25px;
    height: 25px;
    margin: 10px auto 5px;

    @include lg {
      font-size: 40px;
      height: 40px;
      margin: 20px auto 20px;
      width: 40px;
     }

     .icon-check {
       color: $color-tradewind;
     }
  }
}