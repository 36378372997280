@import '../../../assets/sass/global';

.progressStepper {
    margin-top: 3rem;
    justify-content: center;
    align-items: center;
    counter-reset: my-sec-counter;

    &__container {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 40px !important;
        height: 40px;
        color: $color-shuttle-gray;
        background-color: $color-silver;
        border-radius: 50%;
        counter-increment: my-sec-counter;

        &::before {
            content: counter(my-sec-counter);
            position: absolute;
            font-size: 16px;
            font-weight: bold;

            @include xl {
                display: none;
            }
        }

        &--complete {
            background-color: $color-tradewind;
            color: white;

            &::before {
                font-family: $font-family-icons;
                content: "\ea10";
                position: absolute;
                font-size: 22px;

                @include xl {
                    display: none;
                }
            }
        }
        &--inprogress {
            background-color: $color-burnt-sienna;
            color: white;

            &::before {
                content: counter(my-sec-counter);
                position: absolute;
                font-size: 16px;
                font-weight: bold;

                @include xl {
                    display: none;
                }
            }
        }

        @include xl {
            width: 100%;
            border-radius: 8px;
            box-shadow: $box-shadow;
            margin-bottom: 10px;

            &:not(:first-child) {
                border-radius: 0 8px 8px 0;

                &::before {
                    content: " ";
                    display: block;
                    width: 20px;
                    height: 40px;
                    background-color: inherit;
                    position: absolute;
                    right: 100%;
                }
            }

            @for $i from 2 through 16 {
                &:nth-child(#{$i}) {
                    z-index: -#{$i};

                    &::before {
                        z-index: -#{$i};
                    }
                }
            }
        }
    }

    &__connector {
        &:not(:last-child) {
            display: block;
            width: calc(14.3% - 45.7px);
            height: 5px;
            background-color: $color-silver;

            @include xl {
                display: none;
            }
        }
    }

    &__step {
        display: none;
        font-size: 16px;
        line-height: 18px;
        font-weight: bold;
        text-align: center;

        @include xl {
            display: block;
        }
    }

}