@import './variables.scss';

@font-face {
  font-family: 'fs_elliotbold';
  src: url('../fonts/fselliot/fselliot-bold-webfont.woff2') format('woff2'),
  url('../fonts/fselliot/fselliot-bold-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'fs_elliotregular';
  src: url('../fonts/fselliot/fselliot-regular-webfont.woff2') format('woff2'),
  url('../fonts/fselliot/fselliot-regular-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'mygrainger-icons';
  src:  url('../fonts/icons/icomoon.eot?b1161f');
  src:  url('../fonts/icons/icomoon.eot?b1161f#iefix') format('embedded-opentype'),
  url('../fonts/icons/icomoon.ttf?b1161f') format('truetype'),
  url('../fonts/icons/icomoon.woff?b1161f') format('woff'),
  url('../fonts/icons/icomoon.svg?b1161f#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: $font-family-icons !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
