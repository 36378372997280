.list {
  list-style: none;
  padding: 0;

  &--unstyled {
    margin: 0;
  }

  &--inline {
    display: flex;
  }
}
