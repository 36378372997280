@import '../../../assets/sass/global';

.asperato-iframe {
  &__container {
    margin-bottom: 40px;
    justify-content: center;
    max-width: 530px;
  }

  &__iframe {
    border: none;
    height: 750px;

    @media all and (min-width: 510px) {
      height: 580px;
    }

    @include md {
      height: 750px;
    }

    @include lg {
      height: 590px;
    }
  }
}
