@import '../../assets/sass/global';

.referencing {

  &__iframe-container {
    margin-bottom: 40px;
    justify-content: center;
  }

  &__iframe {
    border: none;
    height: 1000px;

    @include lg {
      height: 900px;
    }

    &--submit {
      height: 700px;
      @include sm {
        height: 530px;
      }
      @include lg {
        height: 500px;
      }
      @include lg {
        height: 450px;
      }
    }
  }
}
