@import "../../assets/sass/global";

.householdSummary {
    &__buttons {
        display: flex;
        flex-wrap: wrap;
        margin-bottom: 20px;

        a {
            text-decoration: none;
            margin-right: 10px;
        }
    }
}