@import '../../../assets/sass/global';

.menu {
  height: 0;
  width: 100%;
  position: fixed;
  z-index: 10000;
  top: 0;
  left: 0;
  background-color: #4e8382;
  overflow-y: hidden;
  transition: 0.5s;
  color: #fff;

  a {
    color: white;
  }

  li {
    color: #e6e6e6;
  }

  &__header {
    font-family: $font-family-headings;
    text-transform: lowercase;
    font-size: 23px;
    padding: 20px 0;

    @include md {
      font-size: 26px;
    }

    span {
      color: $color-tradewind;
    }
  }

  &__list {
    list-style-type: none;
    padding: 0;
    margin: 0;

    li a {
      font-size: 20px;
      transition: 0.3s;
      text-decoration: none;
      font-weight: 700;
      padding: 15px 0;
      display: inline-block;
    }   
  
  }

  &__socials{
    position: absolute;
    bottom:0px;
    display:flex;
  }

  &__close.button {
    font-size: 23px;
    text-decoration: none;
    float: right;
    color: #fff;
    width: 25px;
    @include lg {
      font-size: 30px;
    }
  }
} 

.menu_buttons {
  float: right;
  display: flex;

  .button {
    margin-right: 1em;
    cursor: pointer;

    &.navigation-logout-button {
      color: $color-oxford-blue;
      background-color: $color-white;
      text-decoration: none;

      &:hover {
        color: $color-tradewind;
      }
    }
  }
}





