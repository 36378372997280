@import '../../../assets/sass/global';

.header{
  background-color: $color-white;
  padding: 13px 15px;
  border-bottom: 1px solid grey;

  &__title {
    font-family: $font-family-headings;
    color: $color-scorpion;
    text-transform: lowercase;
    font-size: 23px;
    padding: 7px 0;

    @include lg {
      font-size: 26px;
    }

    span {
      color: $color-tradewind;
    }
  }
}