.directDebit {

    .table {
        tr:last-child {
            font-weight: normal;
        }
    }

    .directDebitForm {
        max-width: 600px;
    }

}
