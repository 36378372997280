@import '../../../assets/sass/global';

.timer {
    font-family:$font-family-headings;
    font-size: 30px;
    color:$color-burnt-sienna;
    margin: 30px 0;

    &__text {
        font-size: 16px;
    }
}