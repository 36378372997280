@import '../../assets/sass/global';

.advancePayment {

  &__banner {
    background-color: $color-cararra;
    border-radius: 6px;
    padding: 0 20px;
    margin: 40px 0;
    box-shadow: $box-shadow;
    max-width: 600px;
    font-size: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: $color-orient;
  }

  &__amount {
    font-weight: 700;
    padding: 0 10px 0 20px;
  }

  &__loading {
    position: absolute;
    max-width: 300px;
  }

}
