@import '../../../assets/sass/global';

.savedInput {
  line-height: 50px;
  text-transform: uppercase;
  overflow-wrap: anywhere;

  &__label {
    font-weight: bold;
    color: $color-orient;
  }

  &__value {
    color: $color-friar-gray;
    &--lowercase{
        text-transform: lowercase;
    }
  }
}