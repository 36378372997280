
@import '../../../assets/sass/global';

.table {
  border: 1px solid grey;
  border-radius: 8px;
  color: $color-mindshaft;
  border-collapse: collapse;
  overflow: hidden;
  width: 100%;
  margin: 0 0 30px;
  background-color: $color-off-white;
  max-width: 600px;

  thead {
    background-color: $color-oxford-blue;
    color: white;
  }

  td {
    padding: 20px 20px;
    border-bottom: 1px solid $color-dusty-gray;

    &:nth-child(even){
      text-align: right;
    }
  }

  tr {
    border: $color-mindshaft;
    &:last-child {
      font-weight: 700;
    }
  }

}