@import '../../../assets/sass/global';

.directDebitForm {

    &__container {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
    }

    button {
        height: fit-content;
        align-self: center;
    }

    &__label {
        font-weight: bold;
        color: $color-orient;
        line-height: 50px;
        text-transform: uppercase;
    }

    &__value {
        font-weight: bold;
        line-height: 50px;
        color: $color-friar-gray;
    }

    .textInput {
        margin-right: 10px;
        width: 50%;
    }
}
