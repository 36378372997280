.icon-check:before {
  content: "\e90a";
}
.icon-wifi:before {
  content: "\e91b";
}
.icon-facebook:before {
  content: "\ea90";
}
.icon-instagram:before {
  content: "\ea92";
}
.icon-twitter:before {
  content: "\ea96";
}
.icon-loop2:before {
  content: "\ea2e";
}
.icon-check:before {
  content: "\ea10";
}

